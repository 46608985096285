<template>
  <div>
    <v-speed-dial
      v-model="floatingMenuShow"
      small
      fixed
      left
      bottom
      transition="slide-y-reverse-transition"
      class="test-fab--wrap"
      elevation="10"
    >
      <template #activator>
        <v-btn v-model="floatingMenuShow" fab small class="test-fab--btn" :class="{ active: floatingMenuShow }">
          <v-icon>icon-bottom-navigation-benefits</v-icon>
        </v-btn>
      </template>
      <!-- 메뉴 다이얼로그 -->
      <v-dialog
        v-model="floatingMenuShow"
        scrollable
        transition="dialog-bottom-transition"
        overlay-color="rgb(0,0,0)"
        overlay-opacity="0.7"
      >
        <v-card>
          <v-card-title class="sc-dialog-fullscreen-header" style="display: flex">
            <v-btn absolute right icon class="close-btn" @click="floatingMenuShow = !floatingMenuShow">
              <v-icon>icon-header-close</v-icon>
            </v-btn>
            <h3>테스트 메뉴 선택</h3>
          </v-card-title>
          <v-card-text>
            <v-list height="500">
              <v-list-item @click="onClickJnrIndex">[주니어] 개발팀 인덱스</v-list-item>
              <!-- <v-list-item @click="goToCouponBox">[혜택] 쿠폰함 테스트</v-list-item> -->
              <!-- <v-list-item @click="goToCouponMain">[혜택] 쿠폰혜택 테스트</v-list-item> -->
              <v-list-item @click="goToBingoLab">[혜택] 빙고 이벤트 테스트</v-list-item>
              <v-list-item @click="onClickMydataSampleLogin">[마데] Mydata 임시 로그인</v-list-item>
              <v-list-item @click="goToNewSampleBridge">[공통] 통합 브릿지 테스트</v-list-item>
              <v-list-item @click="onClickSampleBridge">[공통] 온고 브릿지 테스트</v-list-item>
              <v-list-item @click="onClickToSampleIndex">[공통] 샘플페이지 테스트</v-list-item>
              <v-list-item @click="onClickToSamplePageMove">[공통] 페이지이동 테스트</v-list-item>
              <!-- <v-list-item @click="onClickSampleLogout">[공통] 임시 로그아웃</v-list-item> -->
              <v-list-item @click="onClickSampleLogin">[공통] 임시 로그인</v-list-item>
              <!-- <v-list-item @click="onClickScriptLog">[공통] 스크립트 로그 조회</v-list-item> -->
              <v-list-item @click="onClickOpenbankTest">[공통] 오픈뱅킹 계정변경</v-list-item>
              <v-list-item @click="onClickCardSessionTest">[공통] 삼성카드 세션데이터 조회</v-list-item>
              <v-list-item @click="onClickMnmMbUpdTest">[공통] 모니모 회원 DB 업데이트</v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-speed-dial>
    <!-- 스크립트 로그 조회 -->
    <!-- <v-bottom-sheet
      :key="`script-Log-${bottomSheetKey}`"
      v-model="scriptLogSheet"
      scrollable
      content-class="test-page-list--wrap"
    >
      <v-card>
        <v-card-title class="pa-4 blue darken-2">
          스크립트 로그 조회
          <v-btn style="min-width: 40px; width: 45px" @click="clearScriptLog()"> 삭제 </v-btn>
          <v-btn style="min-width: 40px; width: 45px" @click="moveLatestScriptLog()"> 아래 </v-btn>
          <v-btn style="min-width: 40px; width: 45px" @click="moveFirstScriptLog()"> 위 </v-btn>
        </v-card-title>
        <v-card-text ref="logListWrap" class="px-0 white">
          <v-list v-if="scriptLog.length > 0" dense class="py-0">
            <template v-for="(item, index) in scriptLog">
              <v-list-item :key="`scriptLog-${index}`" style="padding: 0 5px 0 5px">
                <v-list-item-content v-if="item.level === 'group'">
                  <v-card :outlined="true">
                    <v-list dense>
                      <template v-for="(item2, index2) in item.log">
                        <v-list-item :key="`scriptLog-group-${index2}`">
                          <v-list-item-content
                            v-if="item2.level === 'table'"
                            style="width: 100%; padding-bottom: 6px; border-top: 1px solid #eee"
                          >
                            <v-data-table
                              v-for="(table, index3) in item2.log"
                              :key="`scriptLog-table-list-${index3}`"
                              :headers="table.headers"
                              :items="table.items"
                              :mobile-breakpoint="0"
                              :hide-default-footer="true"
                            >
                            </v-data-table>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                            <pre
                              style="word-break: break-all; font-size: 0.75rem; white-space: pre-wrap"
                              v-text="`[${item2.level}]${item2.log}`"
                            ></pre>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="item.level === 'table'"
                  style="width: 100%; padding-bottom: 6px; border-top: 1px solid #eee"
                >
                  <v-data-table
                    v-for="(table, index2) in item.log"
                    :key="`scriptLog-table-list-${index2}`"
                    :headers="table.headers"
                    :items="table.items"
                    :mobile-breakpoint="0"
                    :hide-default-footer="true"
                  >
                  </v-data-table>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <pre
                    style="word-break: break-all; font-size: 0.75rem; white-space: pre-wrap"
                    v-text="`[${item.level}]${item.log}`"
                  ></pre>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet> -->
    <!-- 임시 로그인 바텀시트 -->
    <v-bottom-sheet
      :key="`sample-login-${bottomSheetKey}`"
      v-model="sampleLoginSheet"
      scrollable
      content-class="test-sample-bridge--wrap"
    >
      <div class="bridge-api-wrapper" style="background: white">
        <h3 style="text-align: center">임시 로그인</h3>
        <hr />
        <h4>모니모고객관리번호</h4>
        <v-text-field
          v-model="mnmCstMngtNo"
          class="bridge-req-params"
          label="모니모고객관리번호"
          clear-icon="icon-x-delete"
          hint="M0000085010"
        ></v-text-field>
        <br />
        <hr />
        <h4>결과</h4>
        <div class="callback-data">{{ sampleLoginResult }}</div>
        <hr />
        <br />
        <v-btn class="bottom primary" @click="sampleLogin">임시로그인</v-btn>
      </div>
    </v-bottom-sheet>
    <!-- 임시 로그아웃 바텀시트 -->
    <!-- <v-bottom-sheet
      :key="`sample-logout-${bottomSheetKey}`"
      v-model="sampleLogoutSheet"
      scrollable
      content-class="test-sample-bridge--wrap"
    >
      <div class="bridge-api-wrapper" style="background: white">
        <h3 style="text-align: center">임시 로그아웃</h3>
        <hr />
        <h4>모니모고객관리번호</h4>
        <v-text-field
          v-model="mnmCstMngtNo"
          class="bridge-req-params"
          label="모니모고객관리번호"
          clear-icon="icon-x-delete"
          hint="M0000085010"
        ></v-text-field>
        <br />
        <hr />
        <h4>결과</h4>
        <div class="callback-data">{{ sampleLogoutResult }}</div>
        <hr />
        <br />
        <v-btn class="bottom primary" @click="sampleLogout">임시로그아웃</v-btn>
      </div>
    </v-bottom-sheet> -->
    <!-- 카드세션 바텀시트 -->
    <v-bottom-sheet
      :key="`sample-brdige-${bottomSheetKey}`"
      v-model="cardSessionBridgeSheet"
      scrollable
      content-class="test-sample-bridge--wrap"
    >
      <div class="bridge-api-wrapper" style="background: white">
        <v-subheader style="display: block; height: 110px">
          <h3>{{ cardSessionBridgeSpec.apiAlias }}</h3>
          <strong>[{{ cardSessionBridgeSpec.apiHandler }}.{{ cardSessionBridgeSpec.apiName }}]</strong>
          <br />
          {{ cardSessionBridgeSpec.apiDesc }}
        </v-subheader>
        <hr />
        <h4>네이티브 요청 데이터</h4>
        <v-textarea
          v-model="cardSessoinBridgeParam"
          class="bridge-req-params"
          rows="5"
          label="네이티브 요청 데이터 JSON"
          clear-icon="icon-x-delete"
          hint="{key1:value1, key2:value2}"
        ></v-textarea>
        <br />
        <hr />
        <h4>네이티브 콜백 데이터</h4>
        <div class="callback-data">{{ getCardSessionBridgeCallbackData }}</div>
        <hr />
        <br />
        <v-btn class="bottom primary" @click="cardSessionBridgeCall">브릿지호출</v-btn>
      </div>
    </v-bottom-sheet>
    <!-- 임시 로그인 바텀시트 -->
    <v-bottom-sheet
      :key="`mydata-sample-login-${bottomSheetKey}`"
      v-model="mydataSampleLoginSheet"
      scrollable
      content-class="test-sample-bridge--wrap"
    >
      <div class="bridge-api-wrapper" style="background: white">
        <h3 style="text-align: center">Mydata 임시 로그인</h3>
        <hr />
        <h4>모니모고객관리번호</h4>
        <v-text-field
          v-model="mnmCstMngtNo"
          class="bridge-req-params"
          label="모니모고객관리번호"
          clear-icon="icon-x-delete"
          hint="M0000085010"
        ></v-text-field>
        <br />
        <hr />
        <h4>결과</h4>
        <div class="callback-data">{{ mydataSampleLoginResult }}</div>
        <hr />
        <br />
        <v-btn class="bottom primary" @click="mydataSampleLogin">임시로그인</v-btn>
      </div>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { sampleLogin, sampleLogout } from '@/api/pfm/sample/sampleLogin.js';
import { selData } from '@/api/pfm/test';
import { mydataLogin } from '@/api/mdp/test';
import CommonUtil, { getLoginUser } from '@/utils/common-util';
import { toPfmUrl } from '@/utils/url-parser';

export default {
  data() {
    return {
      // 테스트 메뉴 팝업 노출여부
      floatingMenuShow: false,
      bottomSheetKey: 0,

      // script log
      // scriptLog: this.$log.getScriptLog() || [],
      // scriptLogSheet: false,

      // Sample login
      sampleLoginSheet: false,
      mydataSampleLoginSheet: false,
      mnmCstMngtNo: '', // 사용자 모관번
      sampleLoginResult: false,
      mydataSampleLoginResult: false,

      // Sample logout
      // sampleLogoutSheet: false,
      sampleLogoutResult: false,

      // Sample Bridge
      cardSessionBridgeSheet: false,
      cardSessionBridgeSpec: {
        apiAlias: '삼성카드 세션데이터 조회',
        apiHandler: 'PFMAppInterface',
        apiName: 'getScssnData',
        apiDesc: '삼성카드 세션 및 중복로그인방지 값을 조회한다.',
        params: {},
        callback: data => {
          this.$log.log('getScssnDataCallback!', data);
          this.selSessionData(data);
        },
      },
      cardSessoinBridgeParam: '{}',
      cardSessionBridgeCallback: {},
    };
  },
  computed: {
    getCardSessionBridgeCallbackData() {
      let callbackData = '{}';
      try {
        callbackData = JSON.stringify(this.cardSessionBridgeCallback, null, '\t');
      } catch (ignore) {}
      return callbackData;
    },
  },
  async mounted() {
    const vm = this;
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      vm.$log.error({
        msg,
        url,
        lineNo,
        columnNo,
        error: JSON.stringify(error, null, 2),
      });
    };
    // 모관번 가져오기
    this.mnmCstMngtNo = (await getLoginUser())?.loginUser?.mnmCstMngtNo || 'M00000XXXXX';
  },
  destroyed() {
    window.onerror = null;
  },
  methods: {
    // 카드 세션데이터 조회
    async selSessionData($mydata) {
      const xscssn = $mydata.data.scssn;
      const xencst = $mydata.data.encst;
      const resultData = { scssn: xscssn, encst: xencst };
      await selData(resultData);
    },
    // onClickScriptLog() {
    //   this.bottomSheetKey++;
    //   this.scriptLogSheet = true;
    // },
    // clearScriptLog() {
    //   this.$log.clearScriptLog();
    //   this.scriptLog = this.$log.getScriptLog();
    // },
    moveLatestScriptLog() {
      this.$refs.logListWrap.scrollTop = 9999999;
    },
    moveFirstScriptLog() {
      this.$refs.logListWrap.scrollTop = 0;
    },
    /** 브릿지 테스트 */
    onClickSampleLogin() {
      this.bottomSheetKey++;
      this.sampleLoginSheet = true;
    },
    /** 브릿지 테스트 */
    onClickMydataSampleLogin() {
      this.bottomSheetKey++;
      this.mydataSampleLoginSheet = true;
    },
    // 임시로그인 함수
    async sampleLogin() {
      const loginResult = await sampleLogin({
        loginId: this.mnmCstMngtNo,
      });

      if (loginResult?.success) {
        this.$storageUtil.store.set('loginUser', { ...loginResult?.result, timestamp: new Date().getTime() });
        this.sampleLoginResult = loginResult?.success;
      }
    },
    // Mydata 임시로그인 함수
    async mydataSampleLogin() {
      const loginResult = await sampleLogin({
        loginId: this.mnmCstMngtNo,
      });

      if (loginResult?.success) {
        const cstMngtNo = loginResult?.result?.cstMngtNo;
        const mdLoginRes = await mydataLogin(cstMngtNo);
        this.$log.log('[공통-SampleMydataLogin] mdLoginRes: ', mdLoginRes);
        if (mdLoginRes) {
          this.$storageUtil.store.set('loginUser', { ...loginResult?.result, timestamp: new Date().getTime() });
          this.mydataSampleLoginResult = mdLoginRes;
        }
      }
    },
    // 온고 브릿지 테스트 페이지 이동
    onClickSampleBridge() {
      this.floatingMenuShow = false;
      this.$ongobridge.openNewBrowser({ url: toPfmUrl('/sample/BridgeList'), headerYn: 'N' });
    },
    // 신규 브릿지 테스트 페이지 이동
    goToNewSampleBridge() {
      this.floatingMenuShow = false;
      this.$ongobridge.openNewBrowser({ url: toPfmUrl('/sample/NewBridgeList'), headerYn: 'N' });
    },
    /**
     * 모니모 카드세션 변경
     * 1. 고객관리번호 변경 (POMBTBPFM002)
     * 2. 모니모관계사 계약/채널 회원 여부 변경 (POMBTBPFM005)
     */
    onClickCardSessionTest() {
      this.bottomSheetKey++;
      this.cardSessionBridgeSheet = true;
    },
    getParams() {
      let params = {};
      try {
        params = JSON.parse(this.cardSessoinBridgeParam);
      } catch (ignore) {}
      return params;
    },
    // 카드세션 브릿지 요청
    async cardSessionBridgeCall() {
      const { apiHandler, apiName, callback: userCallback } = this.cardSessionBridgeSpec;

      // ongobridge(PFMAppInterface) 인 경우
      if (this.cardSessionBridgeSpec.apiHandler === 'PFMAppInterface') {
        // ongobridge.js에 정의된 함수인 경우
        if (this.$typeUtil.isFunction(this.$ongobridge[apiName])) {
          // Promise 방식
          const resultData = await this.$ongobridge[apiName](this.getParams());
          this.$log.log('Native Callback: ', resultData);
          this.cardSessionBridgeCallback = resultData;
          if (typeof userCallback === 'function') {
            userCallback.apply(
              this,
              Array.prototype.slice.call(this.$typeUtil.isArray(resultData) ? resultData : [resultData]),
            );
          }
        }
        // ongobridge.js에 아직 정의되지 않았지만, ongobridge(PFMAppInterface)로 네이티브 브릿지를 호출하고자 하는 경우
        else {
          // Promise 방식
          const resultData = await this.$ongobridge.exec(apiName, this.getParams());
          this.$log.log('Native Callback: ', resultData);
          this.cardSessionBridgeCallback = resultData;
          if (typeof userCallback === 'function') {
            userCallback.apply(
              this,
              Array.prototype.slice.call(this.$typeUtil.isArray(resultData) ? resultData : [resultData]),
            );
          }
        }
      }

      // 그외의 핸들러로 네이티브에 메시지를 보내는 경우
      else {
        const pfm = window.scard.pfm;
        if (!pfm.getHandler(apiHandler)) {
          pfm.registerHandler(apiHandler);
        }

        // Promise 방식
        const resultData = await pfm.exec(apiHandler, apiName, this.getParams());
        this.$log.log('Native Callback: ', resultData);
        this.cardSessionBridgeCallback = resultData;
        if (typeof userCallback === 'function') {
          userCallback.apply(
            this,
            Array.prototype.slice.call(this.$typeUtil.isArray(resultData) ? resultData : [resultData]),
          );
        }
      }
    },
    // 오픈뱅킹 계정변경 페이지 이동
    onClickOpenbankTest() {
      this.floatingMenuShow = false;
      this.$router.push('/money/test/TEST_OPBKMEM');
    },
    // 특정 페이지로 이동
    onClickToSamplePageMove() {
      this.floatingMenuShow = false;
      this.$ongobridge.openNewBrowser({ url: toPfmUrl('/sample/SamplePageMove'), headerYn: 'N' });
    },
    // 샘플페이지 목록보기
    onClickToSampleIndex() {
      this.floatingMenuShow = false;
      this.$ongobridge.openNewBrowser({ url: toPfmUrl('/sample'), headerYn: 'N' });
    },
    // 임시 로그아웃 바텀시트 열기
    // onClickSampleLogout() {
    //   this.bottomSheetKey++;
    //   this.sampleLogoutSheet = true;
    // },
    // 임시 로그아웃 버튼 이벤트
    async sampleLogout() {
      const logoutResult = await sampleLogout();
      if (logoutResult?.success) {
        this.sampleLogoutResult = logoutResult?.success;
      }
    },
    // 모니모 회원 DB 업데이트
    onClickMnmMbUpdTest() {
      this.floatingMenuShow = false;
      this.$router.push('/sample/prdtest/UPDCSTNO');
    },
    /**
     * 주니어 개발팀 화면 목록 인덱스
     * 주니어 개발 오픈 후 삭제 예정
     */
    onClickJnrIndex() {
      this.floatingMenuShow = false;
      this.$ongobridge.openNewBrowser({ url: toPfmUrl('/sample/SampleJnrIndex'), headerYn: 'N' });
    },
    // 쿠폰 혜택 테스트
    // goToCouponMain() {
    //   this.floatingMenuShow = false;
    //   if (CommonUtil.isMobileApp()) {
    //     this.$ongobridge.openNewBrowser({ url: toPfmUrl('/favor/coupon/UPFMFV0401M0'), headerYn: 'N' });
    //   } else {
    //     this.$router.push('/favor/coupon/UPFMFV0401M0');
    //   }
    // },
    // 쿠폰함 테스트
    // goToCouponBox() {
    //   this.floatingMenuShow = false;
    //   if (CommonUtil.isMobileApp()) {
    //     this.$ongobridge.openNewBrowser({ url: toPfmUrl('/favor/coupon/UPFMFV0402M0'), headerYn: 'N' });
    //   } else {
    //     this.$router.push('/favor/coupon/UPFMFV0402M0');
    //   }
    // },
    // 빙고 이벤트 실험실 이동
    goToBingoLab() {
      const targetPath = '/event/bingo/test/BingoLabIndex';
      this.floatingMenuShow = false;
      CommonUtil.isMobileApp()
        ? this.$ongobridge.openNewBrowser({ url: toPfmUrl(targetPath), headerYn: 'N' })
        : this.$router.push(targetPath);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .test-page-list--wrap.v-dialog.v-bottom-sheet {
    width: 100%;
    margin: 0;
    border-radius: 16px 16px 0 0 !important;
    & > .v-sheet.v-card {
      border-radius: 0 !important;
      background-color: transparent;
      .v-treeview-node__label {
        font-size: 14px;
      }
      &__title {
        backdrop-filter: initial;
      }
    }
    .v-subheader {
      height: 32px;
      padding: 0 8px;
      color: rgba(255, 255, 255, 0.7);
    }
    .sticky {
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .v-list-item__title {
      font-family: $ff-bold;
      font-size: 14px;
    }
  }
  .test-sample-bridge--wrap.v-dialog.v-bottom-sheet {
    width: 100%;
    margin: 0;
    border-radius: 16px 16px 0 0 !important;
    & > .v-sheet.v-card {
      border-radius: 0 !important;
      background-color: transparent;
      .v-treeview-node__label {
        font-size: 14px;
      }
      &__title {
        backdrop-filter: initial;
      }
    }
    .bridge-api-wrapper {
      border: 1px solid black;
      border-radius: 10px;
      padding: 10px !important;
    }
    .v-subheader {
      background: yellow;
    }
    .bridge-req-params {
      padding-left: 10px;
      padding-right: 10px;
    }
    .callback-data {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .test-fab--wrap {
    .v-speed-dial__list {
      align-items: initial;
      margin-left: -6px;
    }
    .test-fab--btn {
      background-color: rgba(255, 255, 255, 0.5) !important;
      &.active {
        background-color: rgba(255, 255, 255, 1) !important;
      }
    }
  }
}
</style>
